.blog {

  &__container {

  }

  &__item {
    flex-direction: row;
    margin: 120px 0 0 0;
  }

  &__title {
    margin: 0 0 40px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 77 / 62 * 100%;
    color: #333333;

  }

  &__content {
    display: inline-flex;
    flex-wrap: wrap;
    @media (max-width: $md1 + px) {
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: center;
    }

  }

}

.post {

  &__card {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 612px;
    height: 641px;
    border: 5px solid #F8F1F1;
    border-radius: 30px;
    margin-bottom: 24px;

    transition: box-shadow 0.3s ease 0s;
    @media (min-width: $md3 + px) {
      &:hover {
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
      }

      &:active {
        box-shadow: none;
      }
    }
  }


  &__card-header {
    padding: 8px 0 0 16px;
  }

  &__card-header-logo {
    display: flex;
    align-items: center;

    font-weight: 400;
    font-size: 28px;
    line-height: 31 / 28 * 100%;
    color: #333333;
  }

  &__card-header-avatar {
    margin: 16px 16px;
  }

  &__card-title {
    height: 433px;
    padding: 0 120px;
  }

  &__card-footer {

  }

  &__like {

  }
}

.article {
  &__container {
    padding: 40px 0 0 0;
    max-width: 824px;
  }

  &__title {
    height: 583px;
  }

  &__topic {
    margin: 40px 0 32px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36 / 28 * 100%;
    color: #333333;

  }

  &__text-content {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28 / 20 * 100%;
    color: #333333;

  }

  &__text-item {
    display: flex;
    flex-direction: column;
    margin: 0 0 32px 0;

  }

  &__item-topic {
    margin: 0 0 23px 0;
  }

  &__item-list {
    margin: 0 0 0 31px;
  }

  &__item-resume {
    margin: 23px 0 0 0;
  }
}

.post-card-even {
  margin-left: 24px;
  @media (max-width: $md1 + px) {
    margin-left: 0;
  }
}

.post-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #F8F1F1;
  margin: 0 0;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 53 / 48 * 100%;
  color: #EBAFAF;
}

