.header {
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1258px;
    padding: 0 10px;
    margin: 0 auto;
  }
  &__menu{
    padding: 10px 0;
    height: 100px;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    @media (max-width: $md3 + px) {
      height: 50px;
    }
  }
  &__logo {
    font-size: 20px;
    font-weight: 400;
    color: #000000;
  }
}
.menu {
  &__icon {

  }
  &__body {
    @media (max-width: $md3 + px) {
      transform: translate(0, -100%);
      transition: transform 0.8s ease 0s;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      overflow: auto;
      padding: 70px 10px 30px 10px;
      background-color: rgba(238, 203, 203, 0.8);
      &_active{
        transform: translate(0, 0%);
      }
    }

  }
  &__list {
    li {
      list-style: none;
      margin: 0 0 30px 0;
      &:last-child {
        margin: 0 0 0 0;
      }
    }
    @media (min-width: $md3 + px) {
      display: flex;
      li {
        margin: 0 24px 0 0;
      }
    }

  }
  &__link{
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    transition: color 0.3s ease 0s;
    @media (min-width: $md2 + px) {
      &:hover {
        color: #EBAFAF;
        //border-bottom: 3px solid
      }
      &:active {
        color: #757575;
      }
    }
    @media (max-width: $md3 + px) {
      font-size: 40px;
      display: block;
      text-align: center;
    }
  }
}
.icon-menu {
  display: none;
  @media (max-width: $md3 + px) {
    display: block;
    position: relative;
    width: 30px;
    height: 18px;
    cursor: pointer;
    z-index: 5;
    span {
      transition: all 0.3s ease 0s;
      top: calc(50% - 1px);
      left: 0;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #000000;
      &:first-child {
        top: 0;
      }
      &:last-child {
        top: auto;
        bottom: 0;
      }
    }
    &._active {
      span {
        transform: scale(0);
        &:first-child {
          transform: rotate(-45deg);
          top: calc(50% - 1px);
        }
        &:last-child {
          transform: rotate(45deg);
          bottom: calc(50% - 1px);
        }
      }
    }

  }
}

//=======================================================================
// Burger Menu===========================================================
//=======================================================================
//.icon-menu {
//  display: none;
//  @media (max-width: $md3 + px) {
//    display: block;
//    position: absolute;
//    top: 18px;
//    right: 10px;
//    width: 30px;
//    height: 18px;
//    cursor: pointer;
//    z-index: 5;
//    span {
//      transition: all 0.3s ease 0s;
//      top: calc(50% - 1px);
//      left: 0;
//      position: absolute;
//      width: 100%;
//      height: 2px;
//      background-color: #000000;
//      &:first-child {
//        top: 0;
//      }
//      &:last-child {
//        top: auto;
//        bottom: 0;
//      }
//    }
//    &._active {
//      span {
//        transform: scale(0);
//        &:first-child {
//          transform: rotate(-45deg);
//          top: calc(50% - 1px);
//        }
//        &:last-child {
//          transform: rotate(45deg);
//          bottom: calc(50% - 1px);
//        }
//      }
//    }
//
//  }
//}