.questions {
  //margin: 0 0 120px 0;

  &__container {
    flex-direction: column;
  }
  &__item-content {

  }

  &__title {
     margin: 0 0 40px 0;
  }

  &__row-text {
    width: 612px;
    height: 112px;
  }

  &__item {

  }
}

