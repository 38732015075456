@font-face {
  font-family: 'arrow';
  src:  url('arrow.eot?xr1ysg');
  src:  url('arrow.eot?xr1ysg#iefix') format('embedded-opentype'),
    url('arrow.ttf?xr1ysg') format('truetype'),
    url('arrow.woff?xr1ysg') format('woff'),
    url('arrow.svg?xr1ysg#arrow') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="_icon-"]::before, [class*=" _icon-"]::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'arrow' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._icon-arrow:before {
  content: "\e900";
}
/*._icon-instagram:before {*/
/*  content: "\ea92";*/
/*}*/
