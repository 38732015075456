.feedback-input {
  width: 100%;
  height: 54px;
  padding: 8px 16px;
  gap: 8px;
  margin: 5px 0;
  border: 1px solid #949494;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
}

.time-slot {
  display: flex;
  flex-direction: column;
  margin: 16px 0 50px 0;
  padding: 0 70px;
  width: 100%;

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    margin: 10px 0;
    gap: 4px;
    width: 100%;
    height: 72px;
    border: 3px solid #F8F1F1;
    border-radius: 15px;
    font-size: 16px;
    line-height: 140%;
    color: #333333;


    &:enabled {
      background: white;

      &.active {
        background: #006edc;
        color: white;

        &:hover {
          background: #1087ff;
        }
      }

      &:hover {
        background: #F8F1F1;
        cursor: pointer;
      }

      //  //&:active {
      //  //  background: #006edc;
      //  //  color: white;
      //  //}
    }

    &:disabled {
      background-color: #f0f0f0;
      border: none;
      cursor: default;
    }
  }
}

