.modal {
  padding: 20px 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
  overflow: auto;


  &.active {
    opacity: 1;
    pointer-events: all;

  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 40px;
    border-radius: 30px;
    background-color: white;
    width: 800px;
    transform: scale(0.5);
    transition: 0.5s all;

    &.active {
      transform: scale(1);

    }
  }

  &__close-icon {
    display: flex;
    width: 100%;
    justify-content: end;
  }
}