.hello {
  &__container {
    padding: 40px 0 0 0;
  }

  &__body {
    align-items: flex-start;
    display: flex;
    margin: 24px 0 0 0;
    @media (max-width: $md3 + px) {
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
    }
  }

  &__title {

  }

  &__content {
    flex: 1 1 auto;
  }

  &__text {
    margin: 0 24px 40px 0;
    @media (max-width: $md3 + px) {
      margin: 0 0 40px 0;
    }
  }

  &__btn {

  }

  &__avatar {
    flex: 0 0 718px;
    height: 560px;
    overflow: hidden;

    img {
      max-width: 100%;
    }
  }
}

//======================================
.about {
  margin: 0 0 120px 0;

  &__container {
    flex-direction: column;
    //margin-top: 120px;
  }

  &__title {
    margin: 0 0 40px 0;
  }

  &__item-1 {
  }

  &__text {
  }

  &__item-content-1 {
  }

  &__item-2 {
  }

  &__item-content-2 {
    display: flex;
  }

  &__card-2 {
    width: 187px;
    height: 187px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 40px 0 0;
    padding: 0 7px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28 / 20 * 100%;
    color: #333333;
  }

  &__item-3 {
  }

  &__item-content-3 {
  }

  &__row-card {
  }

  &__row-text {
    width: 718px;
    height: 56px;
  }

  &__item-4 {
  }

  &__item-content-4 {
    display: flex;
    justify-content: space-between;
  }

  &__card-4 {
    width: 356px;
    height: 356px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__card-title {
    margin: 0 0 16px 0;
    width: 250px;
  }

  &__card-4-text {
    min-height: 84px;
    padding: 0 25px;
  }
}

.row-content {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin: 32px 0;
}

.row-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 48px;
  background-color: #F8F1F1;
  justify-content: space-between;
  font-weight: 400;
  font-size: 28px;
  line-height: 31 / 28 * 100%;
  text-align: center;
  color: #333333;
  border-radius: 30px;
}

.row-text {
  color: #757575;
}


.about-item {
  flex-direction: column;
  margin: 120px 0 0 0;
}






