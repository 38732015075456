@keyframes ldio-nl41gg5mtb {
    0% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

.ldio-nl41gg5mtb div {
    left: 119.225px;
    top: 36.394999999999996px;
    position: absolute;
    animation: ldio-nl41gg5mtb linear 1s infinite;
    background: #faabbb;
    width: 12.549999999999999px;
    height: 42.669999999999995px;
    border-radius: 6.2749999999999995px / 8.107299999999999px;
    transform-origin: 6.2749999999999995px 89.10499999999999px;
}

.ldio-nl41gg5mtb div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9166666666666666s;
    background: #faabbb;
}

.ldio-nl41gg5mtb div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.8333333333333334s;
    background: #faabbb;
}

.ldio-nl41gg5mtb div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.75s;
    background: #faabbb;
}

.ldio-nl41gg5mtb div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.6666666666666666s;
    background: #faabbb;
}

.ldio-nl41gg5mtb div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.5833333333333334s;
    background: #faabbb;
}

.ldio-nl41gg5mtb div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5s;
    background: #faabbb;
}

.ldio-nl41gg5mtb div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.4166666666666667s;
    background: #faabbb;
}

.ldio-nl41gg5mtb div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.3333333333333333s;
    background: #faabbb;
}

.ldio-nl41gg5mtb div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.25s;
    background: #faabbb;
}

.ldio-nl41gg5mtb div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.16666666666666666s;
    background: #faabbb;
}

.ldio-nl41gg5mtb div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.08333333333333333s;
    background: #faabbb;
}

.ldio-nl41gg5mtb div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #faabbb;
}

.loadingio-spinner-spinner-pehlc146h7m {
    width: 251px;
    height: 251px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
}

.ldio-nl41gg5mtb {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}

.ldio-nl41gg5mtb div {
    box-sizing: content-box;
}
