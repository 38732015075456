.booking {
  &__container {
    //padding: 40px 0 0 0;
    //max-width: 824px;
  }

  &__title {
    margin: 0 0 40px 0;
  }

  &__content {
    //flex-direction: column;
  }

  &__items {
    display: block;
    margin: 0 auto;
    padding: 40px 0 0 0;
    max-width: 824px;
  }

  &__item-content {
    text-align: center;
    width: 100%;
  }

  &__item-list {
    margin: 0 0 0 30px;
    text-align: left;
  }

  &__item-price {
    margin: 30px 0;
  }

  &__item-title {
    margin: 0 0 40px 0;
    font-family: Lora;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 24px;
    margin-bottom: 35px;
    //width: 400px;
    //min-height: 320px;
    //background: #F8F1F1;
    border-radius: 30px;
    border: 4px solid #F8F1F1;
  }

  &__btn {
    margin-top: 20px;
    background-color: #F8F1F1;
    border-radius: 15px;
    display: inline-flex;
    padding: 24px 48px;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 72px;
    width: 289px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #333333;
    line-height: 120%;
    transition: background-color 0.4s ease 0s;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__title {
      margin: 40px 0 20px 0;
      font-family: Lora, serif;
      height: 35px;
    }

    &__error-title {
      color: red;
    }
  }
}

.disabled {
  cursor: auto;
}

.agreement {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  margin-top: 40px;
  padding: 0 20px;

  &__label {

  }

  &__input {
    margin-right: 10px;
  }
}