.footer {
  //text-align: center;
  padding: 50px 0 50px 0;

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin: 0 0 45px 0;
  }

  &__text {
    margin: 0 0 24px 0;

  }

  &__social {
    margin: 0 0 16px 0;
  }

  &__copy {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #333333;
    margin: 60px 0 5px 0;
    text-align: end;
  }
}

