.reviews {

  &__container {

  }

  &__item {
    flex-direction: column;
    margin: 120px 0 0 0;
  }

  &__title {
    margin: 0 0 40px 0;
  }

  &__reviews-title {

  }

  &__content {
    display: inline-flex;
    flex-wrap: wrap;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 24px;
    margin-bottom: 35px;
    margin-right: 16px;
    width: 400px;
    min-height: 320px;
    background: #F8F1F1;
    border-radius: 30px;


    //flex-direction: column;
    //align-items: center;
    //justify-content: center;
    //text-align: center;
  }

  &__card-title {
    font-family: Lora;
    margin: 0 0 16px 0;

  }

  &__card-text {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26 / 20 * 100%;
    /* or 26px */


    /* text/primary */

    color: #333333;
  }

}

.review-card-even {
  padding: 20px;
  border: 4px solid #F8F1F1;
  background-color: #ffffff;
}

.insta__btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #333333;
  background: none;

  & p:first-child {
    margin: 0 8px 0 0;
  }

  transition: color 0.3s ease 0s;
  @media (min-width: $md4 + px) {
    &:hover {
      color: #EBAFAF;
      cursor: pointer;
    }
    &:active {
      color: #757575;
    }
  }
}
