@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&display=swap&subset=cyrillic-ext");
@import "./fonts/_icon-arrow.css";

@font-face {
  font-family: "GolosTextWebRegular";
  src: url("./fonts/Golos-Text_Regular.woff2") format("woff2"), url("./fonts/Golos-Text_Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GolosTextWebMedium";
  src: url("./fonts/Golos-Text_Medium.woff2") format("woff2"), url("./fonts/Golos-Text_Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GolosTextWebDemiBold";
  src: url("fonts/Golos-Text_DemiBold.woff2") format("woff2"), url("fonts/Golos-Text_DemiBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "icon-fonts";
  src: url("fonts/icon-fonts.eot?fxjf1t");
  src: url("fonts/icon-fonts.eot?fxjf1t#iefix") format("embedded-opentype"), url("fonts/icon-fonts.ttf?fxjf1t") format("truetype"), url("fonts/icon-fonts.woff?fxjf1t") format("woff"), url("fonts/icon-fonts.svg?fxjf1t#icon-fonts") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^=_icon-]::before,
[class*=" _icon-"]::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icon-fonts" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._icon-like:before {
  content: "\e900";
  color: #757575;
}



._icon-arrow:before {
  content: "\e901";
}

@media (max-width: 1024px) {
  ._icon-arrow:before {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  ._icon-arrow:before {
    font-size: 18px;
  }
}

._icon-instagram:before {
  content: "\ea92";
}

._icon-telegram:before {
  content: "\ea95";
}

._icon-linkedin:before {
  content: "\eac9";
}

/*Обнуление*/
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
aside {
  display: block;
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

/*--------------------*/
.wrapper {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

._container {
  max-width: 1248px;
  margin: 0 auto;
  font-family: "GolosTextWebRegular";
}

@media (max-width: 1024px) {
  ._container {
    margin: 0 32px;
    max-width: 960px;
  }
}

@media (max-width: 767.98px) {
  ._container {
    max-width: none;
    margin: 0 24px;
  }
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 32px;
  width: 100%;
}

@media (max-width: 767.98px) {
  .header__content {
    margin: 40px 24px 0 24px;
  }
}


.header__menu {
  padding: 10px 0;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767.98px) {
  .header__menu {
    padding: 0;
    height: 20px;
  }
}


.header__logo {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
}

.header__menu-window {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  padding: 70px 10px 30px 10px;
  background-color: #F8F1F1;
}

@media (max-width: 767px) {
  .header__menu-window {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: height 0.5s ease;
    height: 0;
    overflow: hidden;
  }

  .header__menu-window.open {
    height: 100%;
  }


  .header__menu-window.closing {
    height: 0;
    transition-delay: 0.15s;
  }

  .menu__list.closing {
    opacity: 0;
    transition-delay: 0s;
  }

}

.header__menu-window__close-icon {
  position: absolute;
  top: 40px;
  right: 30px;

}

/* @media (max-width: 767.98px) {
  .menu__body {
    transform: translate(0, -100%);
    transition: transform 0.8s ease 0s;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: auto;
    padding: 70px 10px 30px 10px;
    background-color: rgba(238, 203, 203, 0.8);
  }
  .menu__body_active {
    transform: translate(0, 0%);
  }
} */
.menu__list li {
  list-style: none;
  margin: 0 0 40px 0;
}

.menu__list li:last-child {
  margin: 0 0 0 0;
}

@media (min-width: 767.98px) {
  .menu__list {
    display: flex;
    transition: opacity 0.3s ease;
  }

  .menu__list li {
    margin: 0 24px 0 0;
  }

  .menu__list.open {
    opacity: 1;
  }
}

.menu__link {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  transition: color 0.3s ease 0s;
}

@media (min-width: 991.98px) {
  .menu__link:hover {
    color: #EBAFAF;
  }

  .menu__link:active {
    color: #757575;
  }
}

@media (max-width: 767.98px) {
  .menu__link {
    font-size: 32px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.icon-menu {
  display: none;
}

@media (max-width: 767.98px) {
  .icon-menu {
    display: block;
    position: relative;
    width: 30px;
    height: 18px;
    cursor: pointer;
    z-index: 5;
  }

  .icon-menu span {
    transition: all 0.3s ease 0s;
    top: calc(50% - 1px);
    left: 0;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #000000;
  }

  .icon-menu span:first-child {
    top: 0;
  }

  .icon-menu span:last-child {
    top: auto;
    bottom: 0;
  }

  .icon-menu._active span {
    transform: scale(0);
  }

  .icon-menu._active span:first-child {
    transform: rotate(-45deg);
    top: calc(50% - 1px);
  }

  .icon-menu._active span:last-child {
    transform: rotate(45deg);
    bottom: calc(50% - 1px);
  }
}

.hello__container {
  padding: 40px 0 0 0;
}

.hello__body {
  align-items: flex-start;
  display: flex;
  margin: 24px 0 0 0;
}

@media (max-width: 767.98px) {
  .hello__body {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.hello__content {
  flex: 1 1 auto;
}

.hello__text {
  margin: 0 24px 40px 0;
}

@media (max-width: 1024px) {
  .hello__text {
    font-size: 18px !important;
  }
}


@media (max-width: 767.98px) {
  .hello__text {
    margin: 0 0 32px 0;
  }
}

.hello__avatar {
  flex: 0 0 718px;
  height: 560px;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .hello__avatar {
    flex: 0 0 550px;
    height: 420px;
  }
}

@media (max-width: 767px) {
  .hello__avatar {
    flex: 0 0 440px;
    height: 420px;
    margin-bottom: 24px;
  }
}

@media (max-width: 576px) {
  .hello__avatar {
    flex: 0 0 327px;
    height: 248px;
  }
}

@media (max-width: 400px) {
  .hello__avatar {
    flex: 0 0 256px;
    height: 256px;
  }
}


.hello__avatar img {
  max-width: 100%;
  max-height: 100%;
}

.about {
  margin: 0 0 120px 0;
}

@media (max-width: 1024px) {
  .about {
    margin: 0 0 96px 0;
  }
}

@media (max-width: 767px) {
  .about {
    margin: 0 0 80px 0;
  }
}

.about__container {
  flex-direction: column;
}

.about__title {
  margin: 0 0 40px 0;
}

.about__item-content-2 {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 576px) {

  .about__item-content-2 {
    justify-content: center;
    align-items: center;
  }
}

.about__card-2 {
  width: 187px;
  height: 187px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 40px 32px 0;
  padding: 0 7px;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: #333333;
}


@media (max-width: 576px) {

  .about__card-2 {
    margin: 0 24px 32px 0;
    padding: 0;
  }
}

.about__row {
  display: flex;
  align-items: flex-start !important;
}


.about__row-text {
  width: 50%;
  height: 56px;
}

@media (max-width: 1260px) {

  .about__row-text>p {
    font-size: 18px;
  }
}


@media (max-width: 1024px) {

  .about__row-text>p {
    font-size: 16px;
  }
}

@media (max-width: 767px) {

  .about__row-text {
    width: 100%;
    margin-bottom: 8px;
    margin-top: 24px;
    height: auto;
  }
}


.about__item-content-4 {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1024px) {
  .about__item-content-4 {
    justify-content: normal;
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .about__item-content-4 {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.about__card-4 {
  width: 356px;
  height: 356px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (max-width: 1024px) {
  .about__card-4 {
    margin-right: 84px;
    margin-bottom: 32px;
  }

  .about__card-4:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .about__card-4 {
    width: 327px;
    height: 327px;
    margin-right: 0;
  }
}

.about__card-title {
  margin: 0 0 16px 0;
  width: 250px;
}

.about__card-4-text {
  min-height: 84px;
  padding: 0 25px;
  text-align: center !important;
}

.row-content {
  align-items: center;
  display: flex;
  margin: 32px 0;
  width: 100%;
}

@media (max-width: 767px) {
  .row-content {
    flex-direction: column;
  }

  .row-content:last-child {
    margin-bottom: 80px;
  }

}

.row-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px 48px;
  background-color: #F8F1F1;
  justify-content: center;
  font-weight: 400;
  font-size: 28px;
  line-height: 110.7142857143%;
  text-align: center;
  color: #333333;
  border-radius: 30px;
  width: 50%;
  margin-right: 40px;
}

@media (max-width: 1024px) {
  .row-card {
    margin-right: 24px;

  }
}

@media (max-width: 767px) {
  .row-card {
    font-size: 20px;
    width: 100%;

  }
}

.row-text {
  color: #757575;
}

.about-item {
  flex-direction: column;
  margin: 120px 0 96px 0;
}

@media (max-width: 1024px) {
  .about-item {
    margin-top: 96px;
  }
}

@media (max-width: 767px) {
  .about-item {
    margin-top: 80px;
  }
}

.about__item-3 {
  margin-top: 64px;
}

.booking__title {
  margin: 0 0 40px 0;
}

@media (max-width: 767px) {
  .booking__title {
    margin: 40px 0;
  }
}


.booking__feedback-form {
  width: 100%
}

.booking__items {
  display: block;
  margin: 0 auto;
  padding: 30px 0 0 0;
  max-width: 824px;
}

.booking__item-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 1024px) {
  .booking__items {
    padding: 0;
  }
}

.booking__item-content {
  text-align: center;
  width: 100%;
}

.booking__item-list {
  margin: 0 8px 0 30px;
  text-align: left;
  list-style-type: none;
}

@media (max-width: 767px) {
  .booking__item-list {
    margin: 0;
  }

  .booking__item-list>li {
    margin-bottom: 16px;
  }

  .booking__item-list>li:last-child {
    margin-bottom: 0;
  }
}

.booking__item-price {
  margin: 32px 0;
  text-align: center;
  font-size: 18px;
}

@media (max-width: 767px) {
  .booking__item-price {
    margin-bottom: 12px
  }
}

.booking__item-title {
  margin: 0 0 40px 0;
  font-family: Lora;
}

@media (max-width: 767px) {
  .booking__item-title {
    margin-bottom: 32px
  }
}

.booking__content {
  margin-bottom: 96px;
}

@media (max-width: 767px) {
  .booking__content {
    margin-bottom: 80px;
  }
}

.booking__card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  margin-bottom: 35px;
  border-radius: 30px;
  border: 4px solid #F8F1F1;
}

@media (max-width: 1024px) {
  .booking__card {
    align-items: center;
  }
}

.booking__btn {
  display: block;
  margin-top: 18px;
}

@media (max-width: 767px) {
  .booking__btn {
    width: 100% !important;
  }
}

.booking__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 603px;
}

@media (max-width: 767px) {
  .booking__form {
    width: 450px
  }
}

@media (max-width: 576px) {
  .booking__form {
    width: 327px
  }
}

.booking__form__title {
  margin: 40px 0 20px 0;
  font-family: Lora, serif;
}

.booking__form__error-title {
  color: red;
}

.disabled {
  cursor: auto;
}

.agreement {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  margin-top: 40px;
  padding: 0 20px;
}
.agreement__input {
  margin-right: 10px;
}

.reviews__item {
  flex-direction: column;
  margin: 120px 0 16px 0;
}

@media (max-width: 1024px) {
  .reviews__item {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .reviews__item {
    margin: 40px 0 16px 0;

  }
}

.reviews__title {
  margin: 0 0 40px 0;
}



.reviews__content {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 24px;
}

.reviews__card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  width: 400px;
  min-height: 320px;
  background: #F8F1F1;
  border-radius: 30px;
}

@media (max-width: 1024px) {
  .reviews__card {
    width: 300px;
  }
}

@media (max-width: 767px) {
  .reviews__card {
    width: 100%;
    min-height: auto;
  }
}

.reviews__card-title {
  font-family: Lora;
  margin: 0 0 16px 0;
}

@media (max-width: 1024px) {
  .reviews__card-title {
    font-size: 24px !important;
    line-height: 140%;
  }
}

.reviews__card-text {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #333333;
}

@media (max-width: 1024px) {
  .reviews__card-text {
    font-size: 15px !important;
  }
}

.review-card-even {
  padding: 20px;
  border: 4px solid #F8F1F1;
  background-color: #ffffff;
}

.insta__btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #333333;
  background: none;
  transition: color 0.3s ease 0s;
  margin-top: 16px;
}


@media (max-width: 1024px) {
  .insta__btn>p {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .insta__btn>p {
    font-size: 18px;
  }
}


.insta__btn p:first-child {
  margin: 0 8px 0 0;
}

@media (min-width: 479.98px) {
  .insta__btn:hover {
    color: #EBAFAF;
    cursor: pointer;
  }

  .insta__btn:active {
    color: #757575;
  }
}

.personalDataPolicy__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
}
.personalDataPolicy__title {
  margin: 40px 0 20px 0;
  text-align: center;
  color: #333333;
}
.personalDataPolicy__paragraph {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}
.personalDataPolicy__paragraph__title {
  text-align: center;
  margin-bottom: 20px;
}
.personalDataPolicy__paragraph__content {
  display: flex;
  flex-direction: column;
}
.personalDataPolicy__paragraph__content__item {
  margin-bottom: 15px;
}
.personalDataPolicy__paragraph__content ul {
  margin: 0 0 15px 40px;
}


.questions__container {
  flex-direction: column;
}

.questions__title {
  margin: 0 0 40px 0;
}


@media (max-width: 1260px) {
  .questions__title {
    margin-bottom: 32px
  }
}

.questions__row-text {
  width: 50%;
}

@media (max-width: 1024px) {
  .questions__row-text {
    font-size: 16px !important;
  }
}

@media (max-width: 767px) {
  .questions__row-text {
    margin-top: 24px;
    font-size: 16px !important;
    width: 100%;
    height: auto;
    margin-bottom: 8px;
  }
}


.blog__item {
  flex-direction: row;
  margin: 120px 0 0 0;
}

.blog__title {
  margin: 0 0 40px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 124.1935483871%;
  color: #333333;
}

.blog__content {
  display: inline-flex;
  flex-wrap: wrap;
}

@media (max-width: 1260px) {
  .blog__content {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.post__card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 612px;
  height: 641px;
  border: 5px solid #F8F1F1;
  border-radius: 30px;
  margin-bottom: 24px;
  transition: box-shadow 0.3s ease 0s;
}

@media (min-width: 767.98px) {
  .post__card:hover {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  }

  .post__card:active {
    box-shadow: none;
  }
}

.post__card-header {
  padding: 8px 0 0 16px;
}

.post__card-header-logo {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 28px;
  line-height: 110.7142857143%;
  color: #333333;
}

.post__card-header-avatar {
  margin: 16px 16px;
}

.post__card-title {
  height: 433px;
  padding: 0 120px;
}

.article__container {
  padding: 40px 0 0 0;
  max-width: 824px;
}

.article__title {
  height: 583px;
}

.article__topic {
  margin: 40px 0 32px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 128.5714285714%;
  color: #333333;
}

.article__text-content {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: #333333;
}

.article__text-item {
  display: flex;
  flex-direction: column;
  margin: 0 0 32px 0;
}

.article__item-topic {
  margin: 0 0 23px 0;
}

.article__item-list {
  margin: 0 0 0 31px;
}

.article__item-resume {
  margin: 23px 0 0 0;
}

.post-card-even {
  margin-left: 24px;
}

@media (max-width: 1260px) {
  .post-card-even {
    margin-left: 0;
  }
}

.post-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #F8F1F1;
  margin: 0 0;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 110.4166666667%;
  color: #EBAFAF;
}

.footer {
  padding: 50px 0 50px 0;
}

@media (max-width: 1024px) {
  .footer {
    padding: 0 0 50px 0;
  }
}


.footer__content {
  display: flex;
  flex-direction: column;
}

.footer__title {
  margin: 0 0 48px 0;
}

@media (max-width: 1024px) {
  .footer__title {
    margin: 0 0 24px 0;
  }
}

@media (max-width: 767px) {
  .footer__title {
    margin: 0 0 40px 0;
  }
}

.footer__text {
  margin: 0 0 24px 0;
}

.footer__social {
  margin-top: 96px;
}

@media (max-width: 767px) {
  .footer__social {
    margin-top: 24px;
  }
}

.footer__copy {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #333333;
  margin: 16px 0 5px 0;
  text-align: end;
}

@media (max-width: 767px) {
  .footer__copy {
    margin-top: 40px;
  }
}

.modal {
  padding: 20px 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
  overflow: auto;
}

.modal__close-icon {
  z-index: 1;
  position: absolute;
  top: 24px;
  right: 32px;
}

@media (max-width: 767px) {
  .modal__close-icon {
    top: 40px;
  }
}

@media (max-width: 576px) {
  .modal__close-icon {
    top: 20px;
    right: 20px;
  }
}



.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modal__content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 32px;
  border-radius: 30px;
  background-color: white;
  width: 800px;
  min-height: 400px;
  transform: scale(0.5);
  transition: 0.5s all;
}


@media (max-width: 767px) {
  .modal__content {
    padding: 80px 24px 40px 24px;
  }
}

.modal__content.active {
  transform: scale(1);
}

.react-calendar {
  max-width: 100%;
  background: white;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 6.5px;
  border: 3px solid #F8F1F1;
  border-radius: 15px;
  outline: none;
}

.react-calendar button:disabled {
  border: none;
  cursor: auto;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}




.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #F8F1F1;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--weekend:disabled {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;
}

.react-calendar__month-view__days .react-calendar__tile {
  max-width: initial !important;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  width: 72px;
  height: 72px;
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  font-size: 20px;
  line-height: 16px;
  border: 3px solid #F8F1F1;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .react-calendar__tile {
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 4px !important;
  }
}


.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #F8F1F1;
}

.react-calendar__tile--now {
  background: rgb(238, 203, 203);
}

.react-calendar__tile--now:disabled {
  background: rgb(238, 203, 203);
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #EBAFAF;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.feedback-input {
  width: 100%;
  height: 54px;
  padding: 8px 16px;
  gap: 8px;
  margin: 8px 0;
  border: 1px solid #949494;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
}

.feedback-input:first-child {
  margin-top: 16px;
}


.time-slot {
  display: flex;
  flex-direction: column;
  margin: 16px 0 50px 0;
  padding: 0 70px;
  width: 100%;
}

.time-slot__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  margin: 10px 0;
  gap: 4px;
  width: 100%;
  height: 72px;
  border: 3px solid #F8F1F1;
  border-radius: 15px;
  font-size: 16px;
  line-height: 140%;
  color: #333333;
}

.time-slot__btn:enabled {
  background: white;
}

.time-slot__btn:enabled.active {
  background: #006edc;
  color: white;
}

.time-slot__btn:enabled.active:hover {
  background: #1087ff;
}

.time-slot__btn:enabled:hover {
  background: #F8F1F1;
  cursor: pointer;
}

.time-slot__btn:disabled {
  background-color: #f0f0f0;
  border: none;
  cursor: default;
}

.page {
  min-height: calc(100vh - 80px);
}

.title {
  font-size: 64px;
  color: #333333;
  line-height: 110%;
  font-weight: 400;
}

@media (max-width: 1024px) {
  .title {
    font-size: 50px
  }
}

@media (max-width: 767px) {
  .title {
    font-size: 24px;
    line-height: 120%;
  }
}

.title-italic {
  font-family: Lora;
}

.small-title {
  font-family: 'GolosTextWebRegular';
  font-weight: 400;
  font-size: 48px;
  line-height: 110%;
  color: #333333;
}

@media (max-width: 767px) {
  .small-title {
    font-size: 24px;
    line-height: 120%;
  }
}

.micro-title {
  font-weight: 400;
  font-size: 28px;
  line-height: 110%;
  color: #333333;
}

@media (max-width: 767px) {
  .micro-title {
    font-size: 24px;
    line-height: 128%;
    text-align: center;
  }
}

.text {
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: #333333;
  text-align: start;
}

@media (max-width: 767px) {
  .text {
    font-size: 16px;
  }
}


.btn {
  background-color: #F8F1F1;
  border-radius: 15px;
  display: inline-flex;
  padding: 24px 48px;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 72px;
  width: 289px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
  line-height: 120%;
  transition: background-color 0.4s ease 0s;
}

@media (min-width: 479.98px) {
  .btn:hover {
    background-color: rgb(238, 203, 203);
    cursor: pointer;
  }

  .btn:active {
    background-color: #F8F1F1;
  }

  .btn:disabled {
    background-color: rgba(248, 241, 241, 60%);
    cursor: auto;
  }
}

.card {
  display: flex;
  background-color: #F8F1F1;
  border-radius: 50%;
  text-align: center;
}

.social {
  display: flex;
  align-items: center;
  font-size: 64px;
  color: inherit;
}

.social__item {
  margin: 0 16px 0 0;
}

._icon-linkedin {
  color: #2867B2;
}

._icon-telegram {
  color: #2AABEE;
}

._icon-instagram {
  background: linear-gradient(45deg, rgb(249, 237, 50), rgb(238, 42, 123), rgb(0, 42, 255));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

._icon-like {
  display: block;
  max-width: 34px;
  margin: 16px 0 0 32px;
  color: #757575;
  font-size: 34px;
}

.error__container {
  text-align: center;
}

.error-text {
  color: #e74c3c;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

