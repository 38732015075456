// Подключение шрифтов --------------------
@import "fonts.scss";
// Подключение шрифтов --------------------

// обнуление стилей -----------------------
@import "nullstyle.scss";
// обнуление стилей -----------------------

// Шрифт по умолчанию ---------------------
$fontFamily: "GolosTextWebRegular";
// Шрифт по умолчанию ---------------------

// Переменные -----------------------------
$minWidth: 320;
$maxWidth: 1440;
$maxWidthContainer: 1248;
$md1: $maxWidthContainer + 12;
$md2: 991.98;
$md3: 767.98;
$md4: 479.98;
// Переменные -----------------------------


.wrapper {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.__loaded {

  }
}

//<основная сетка>-------------------------
._container {
  max-width: $maxWidthContainer + px;
  margin: 0 auto;
  font-family: $fontFamily;
  @media (max-width: $md1 + px) {
    max-width: 970px;
  }
  @media (max-width: $md2 + px) {
    max-width: 750px;
  }
  @media (max-width: $md3 + px) {
    max-width: none;
    padding: 0 10px;
  }
}

//</основная сетка>-------------------------

// HEADER ----------------------------------
@import "header";
//------------------------------------------

// HOME ------------------------------------
@import "home";
//------------------------------------------

// BOOKING ---------------------------------
@import "booking";
//------------------------------------------

// REVIEWS ---------------------------------
@import "reviews";
//------------------------------------------

// personalDataPolicy-----------------------
@import "personalDataPolicy";
//------------------------------------------

// QUESTIONS -------------------------------
@import "questions";
//------------------------------------------

// BLOG ------------------------------------
@import "blog";
//------------------------------------------

// FOOTER ----------------------------------
@import "footer";
//------------------------------------------

// MODAL -----------------------------------
@import "modal";
// -----------------------------------------

// CALENDAR --------------------------------
@import "calendar";
// -----------------------------------------

// FORMS -----------------------------------
@import "forms";
// -----------------------------------------

.body {

}

.page {
  min-height: calc(100vh - 80px);
}

.title {
  font-size: 64px;
  color: #333333;
  line-height: 70.4 / 64 * 100%;
  font-weight: 400;

}

.title-italic {
  font-family: Lora;
}

.small-title {
  font-weight: 400;
  font-size: 48px;
  line-height: 52.8 / 48 * 100%;
  color: #333333;
}

.micro-title {
  font-weight: 400;
  font-size: 28px;
  line-height: 30.8 / 28 * 100%;
  color: #333333;
}

.text {
  font-weight: 400;
  font-size: 20px;
  line-height: 28 / 20 * 100%;
  color: #333333;
}

.btn {
  background-color: #F8F1F1;
  border-radius: 15px;
  display: inline-flex;
  padding: 24px 48px;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 72px;
  width: 289px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
  line-height: 24 / 20 * 100%;
  transition: background-color 0.4s ease 0s;
  @media (min-width: $md4 + px) {
    &:hover {
      background-color: rgba(238, 203, 203, 1);
      cursor: pointer;
    }
    &:active {
      background-color: #F8F1F1;
    }
  }
}

.card {
  display: flex;
  background-color: #F8F1F1;
  border-radius: 50%;
  text-align: center;
}

.item-content {
}

.social {
  display: flex;
  //justify-content: center;
  align-items: center;
  font-size: 64px;
  color: inherit;

  &__item {
    margin: 0 16px 0 0;
  }
}

._icon-linkedin {
  color: #2867B2;
}

._icon-telegram {
  color: #2AABEE;
}

._icon-instagram {
  background: linear-gradient(45deg, rgb(249, 237, 50), rgb(238, 42, 123), rgb(0, 42, 255));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

._icon-like {
  display: block;
  max-width: 34px;
  margin: 16px 0 0 32px;
  color: #757575;
  font-size: 34px;
}

.error__container {
  text-align: center;
}

.error-text {
  color: #e74c3c;
}


