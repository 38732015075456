// Подключение шрифтов --------------------
// &display=swap&subset=cyrillic-ext
@import url('https://fonts.googleapis.com/css2?family=Lora:ital@1&display=swap&subset=cyrillic-ext');

@font-face {
	font-family: 'GolosTextWebRegular';
	src:
		url('./fonts/Golos-Text_Regular.woff2') format('woff2'),
		url('./fonts/Golos-Text_Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'GolosTextWebMedium';
	src:
		url('./fonts/Golos-Text_Medium.woff2') format('woff2'),
		url('./fonts/Golos-Text_Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'GolosTextWebDemiBold';
	src:
		url('fonts/Golos-Text_DemiBold.woff2') format('woff2'),
		url('fonts/Golos-Text_DemiBold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
// -----------------------------------------

@import "./fonts/_icon-arrow.css";

// Подключение иконочных шрифтов -----------

@font-face {
  font-family: 'icon-fonts';
  src:  url('fonts/icon-fonts.eot?fxjf1t');
  src:  url('fonts/icon-fonts.eot?fxjf1t#iefix') format('embedded-opentype'),
    url('fonts/icon-fonts.ttf?fxjf1t') format('truetype'),
    url('fonts/icon-fonts.woff?fxjf1t') format('woff'),
    url('fonts/icon-fonts.svg?fxjf1t#icon-fonts') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="_icon-"]::before, [class*=" _icon-"]::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon-fonts' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

._icon-like:before {
  content: "\e900";
  color: #757575;
}
._icon-arrow:before {
  content: "\e901";
}
._icon-instagram:before {
  content: "\ea92";
}
._icon-telegram:before {
  content: "\ea95";
}
._icon-linkedin:before {
  content: "\eac9";
}
// ---------------------------------------
