.personalDataPolicy {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    justify-content: center;
  }

  &__title {
    margin: 40px 0 20px 0;
    text-align: center;
    color: #333333;
  }

  &__paragraph {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;

    &__title {
      text-align: center;
      margin-bottom: 20px;
    }

    &__content {
      display: flex;
      flex-direction: column;

      &__item {
        margin-bottom: 15px;
      }

      ul {
        margin: 0 0 15px 40px;
      }

    }
  }
}